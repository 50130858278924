<template>
  <div>
    <div v-if="loading">
      <h3 class="text-center"><b-spinner></b-spinner> Carregando...</h3>
    </div>
    <EmptyState v-if="(!historico || historico.length == 0) && !loading">
      <h3>Nada encontrado!</h3>
      <p>O paciente ainda não tem nada no histórico...</p>
    </EmptyState>
    <div class="row">
      <div class="col-12">
        <div class="p-4">
          <div
            class="histCard pt-"
            v-for="hist in historico"
            :key="`hist_${hist.id}_${Math.random}`"
          >
            <div
              class="card"
              :class="{ cadCancelado: hist.cancelado == 1 }"
            >
              <div class="card-header">
                <b>{{ hist.data | moment("DD/MM/YYYY") }} </b><br />
                <small>
                  {{ hist.autor }}
                </small>
                <div class="float-right">
                  <small>{{ hist.clinica }} </small>
                </div>
                <b-card
                  v-if="hist.cancelado == 1"
                  class="w-100 m-2 text-danger text-left d-flex justify-space-between"
                >
                  <b>Cancelado </b>
                  <span>
                    {{ hist.dt_cancelado | moment("DD/MM/YYYY HH:mm") }}
                  </span>
                </b-card>
              </div>
              <div class="card-body">
                <ul>
                  <li
                    v-for="it in hist.itens"
                    :key="`it_${it.id + Math.random}`"
                    class="mb-3"
                  >
                    {{ it.descricao }}
                    <small v-if="it.exames && it.exames.medicoNome">
                      <br />
                      <b>Médico:</b> {{ it.exames.medicoNome }}
                    </small>

                    <small v-if="paciente_id != it.paciente_id">
                      <br />
                      <b>Paciente:</b> {{ it.paciente }}
                    </small>
                    <div
                      v-if="
                        it.tipo_venda == 'Exame' &&
                        it.exames &&
                        it.exames.exames
                      "
                    >
                      <hr />
                      <b-table
                        small
                        style="font-size: 10px"
                        :items="it.exames.exames"
                        :fields="[
                          { key: 'nome' },
                          {
                            key: 'valor_venda',
                            label: 'Valor',
                            class: 'text-right',
                          },
                        ]"
                        v-if="it.exames.exames"
                      >
                        <template #cell(valor_venda)="row">
                          {{ row.item.valor_venda | currencyWithMask }}
                        </template>
                      </b-table>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="card-footer d-flex justify-content-between">
                <div><b-icon-headset /> {{ hist.usuario }}</div>
                <div class="d-flex">
                  <b-btn
                    variant="none"
                    type="button"
                    class="float-right"
                    @click="openPgs(hist)"
                  >
                    <b-icon-menu-button-wide /> PG. Fornecedores
                  </b-btn>
                  <b-btn
                    variant="none"
                    type="button"
                    class="float-right"
                    @click="openVenda(hist)"
                  >
                    <b-icon-arrow-up-right-circle /> Abrir Venda
                  </b-btn>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-open-venda"
      size="lg"
      hide-footer
      hide-header
    >
      <VendaDetail
        :vendaId="idOpen"
        v-if="idOpen && idOpen != ''"
        @close="$bvModal.hide('modal-open-venda')"
      />
    </b-modal>
    <b-modal
      id="modal-open-pgs"
      size="lg"
      hide-footer
      title="Pagamentos Realizados p/ Fornecedores"
    >
      <PacienteHistoricoVendaPgFornDetail :historicoObj="vndObj" />
    </b-modal>
  </div>
</template>

<script>
import EntidadesLib from "../../../libs/EntidadesLib";
// import AnamneseListagemFolha from "../../Anamnese/Listagem/AnamneseListagemFolha.vue";
import anamneseImg from "../../../assets/img/icones/menu/icons8_treatment_40px.png";
import fidelizacaoImg from "../../../assets/img/icones/menu/loyalty_40px.png";
import receitaImg from "../../../assets/img/icones/menu/icons8_autograph_40px.png";
import exameImg from "../../../assets/img/icones/menu/icons8_heart_with_pulse_40px.png";
import caixaImg from "../../../assets/img/icones/menu/cash_register_40px.png";
import horarioImg from "../../../assets/img/icones/menu/today_52px.png";
import atestadoImg from "../../../assets/img/icones/menu/icons8_user_clock_40px.png";
import EmptyState from "../../common/EmptyState.vue";
import VendaDetail from "../../Caixas/Vendas/VendaDetail.vue";
import PacienteHistoricoVendaPgFornDetail from "./PacienteHistoricoVendaPgFornDetail.vue";

export default {
  name: "PacienteHIstorico",
  components: {
    EmptyState,
    VendaDetail,
    PacienteHistoricoVendaPgFornDetail,
  },
  props: {
    paciente_id: [String, Number],
  },
  async created() {
    await this.carregar();
  },
  data() {
    return {
      loading: false,
      historico: [],
      icons: {
        Anamnese: anamneseImg,
        Fidelizacao: fidelizacaoImg,
        Receita: receitaImg,
        Exame: exameImg,
        Venda: caixaImg,
        Horario: horarioImg,
        Atestado: atestadoImg,
      },
      idOpen: null,
      vndObj: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    openPgs(vnd) {
      this.vndObj = vnd;
      this.$bvModal.show("modal-open-pgs");
    },
    openVenda(vnd) {
      console.log(vnd);
      this.idOpen = vnd.id;
      this.$bvModal.show("modal-open-venda");
    },
    async carregar() {
      this.loading = true;
      if (this.paciente_id) {
        try {
          this.historico = await EntidadesLib.getPacienteHistorico({
            paciente_id: this.paciente_id,
          });
          console.log("hsit", this.historico);
          if (this.historico && this.historico.length > 0) {
            this.historico = this.historico.filter((x) => x.tipo === "Venda");
          }
        } catch (error) {
          this.showToast("error", error.message, 3500);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/historico.scss";
@import "@/assets/scss/default.scss";
@import "@/assets/scss/venda.scss";
.list-header {
  display: flex;
  padding: 5px;
  padding-left: 0;

  > ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  ul li {
    list-style: none;
  }
}

th {
  background-color: $primary;
  color: white;
}
.cadCancelado {
  opacity: 0.4;
  background-color: #f9f9f9;
}
</style>
