<template>
  <div>
    <div class="text-center">
      <div v-if="!canPrint">
        <b-spinner /> <br />
        <small
          >Aguarde o botão aparecer e estar habilitado para clicar antes de
          tentar imprimir.</small
        >
      </div>
      <b-btn
        variant="none"
        v-if="canPrint"
        @click="imprimir"
        ><b-icon-printer /> Imprimir</b-btn
      >
    </div>
    <div
      v-if="!printNow"
      class="text-center p-4"
    >
      <b-spinner /> <br />
      aguarde...
    </div>
    <div id="printDiv"> 
      <print-component
        :filename="`Fatura ${fatura.entidadeNome}`"
        v-if="fatura"
        :printNow="printNow"
        @done="$emit('done')"
        :show="true"
        :enableDownload="downloadPDF"
        :titulo="`Fatura ${fatura.entidadeNome} - ${fatura.data}`"
        @afterDownload="afterDownload"
      >
        <section class="pdf-item">
          <table
            class="table table-sm mt-1"
            style="font-size: 10px"
          >
            <tbody>
              <tr>
                <th class="bg-light">Código Controle</th>
                <td colspan="99">{{ fatura.id }}</td>
              </tr>
              <tr>
                <th class="bg-light">Fornecedor</th>
                <td>
                  {{ fatura.entidadeNome }}
                  <div>
                    <small> <b>CPF:</b> {{ fatura.cpf }} </small>
                  </div>
                </td>
                <th class="bg-light">Pagamento</th>
                <td class="text-center">{{ fatura.data }}</td>
                <td
                  :class="{
                    'text-right':
                      fatura.vl_desconto > 0 || fatura.vl_acrescimo > 0,
                    'text-center': !fatura.vl_desconto && !fatura.vl_acrescimo,
                  }"
                  rowspan="4"
                  style="
                    border-left: 1px solid #ccc;
                    min-width: 100px;
                    font-size: 15px;
                  "
                >
                  <div class="text-center bg-light pb-1 pt-1">
                    <b class="text-center">Valor</b>
                  </div>
                  <hr class="mt-0 mb-1" />
                  <span
                    v-if="fatura.vl_desconto > 0 || fatura.vl_acrescimo > 0"
                  >
                    <b>Subtotal: </b> {{ fatura.vl_subtotal | currency }}
                    <span
                      v-if="fatura.vl_desconto > 0"
                      class="text-left text-danger"
                    >
                      <br />
                      <b>Desconto: </b> {{ fatura.vl_desconto | currency }}
                    </span>
                    <span
                      v-if="fatura.vl_acrescimo > 0"
                      class="text-success"
                    >
                      <br />
                      <b>Acrescimo: </b> {{ fatura.vl_acrescimo | currency }}
                    </span>
                    <br />
                    <hr class="mt-1 mb-1" />
                    <b>Total: </b>
                  </span>
                  {{ fatura.vl_total | currency }}
                </td>
              </tr>
              <tr>
                <th class="bg-light">Referente á</th>
                <td colspan="3">{{ fatura.referente }}</td>
              </tr>
              <tr>
                <th class="bg-light">Observações</th>
                <td colspan="3">{{ fatura.obs }}</td>
              </tr>
              <tr>
                <th class="bg-light">Forma Pagamento</th>
                <td colspan="3">
                  <small v-if="fatura.forma_pagamento">
                    <b>Forma de Pagamento:</b>
                    {{
                      ["Corrente", "Poupança"].includes(
                        fatura.forma_pagamento.tipo
                      )
                        ? "Bancário - " + fatura.forma_pagamento.tipo
                        : fatura.forma_pagamento.tipo
                    }}<br />
                    <span
                      v-if="
                        fatura.forma_pagamento.banco &&
                        fatura.forma_pagamento.banco != '' &&
                        fatura.forma_pagamento.tipo != 'PIX'
                      "
                    >
                      <b
                        >{{
                          fatura.forma_pagamento.tipo == "PIX"
                            ? "Tipo:"
                            : "Banco:"
                        }}
                      </b>
                      {{ fatura.forma_pagamento.banco }}
                    </span>
                    <span
                      v-if="
                        fatura.forma_pagamento.agencia &&
                        fatura.forma_pagamento.agencia != '' &&
                        fatura.forma_pagamento.tipo != 'PIX'
                      "
                    >
                      <br />
                      <b>Agencia: </b> {{ fatura.forma_pagamento.agencia }}
                    </span>
                    <span
                      v-if="
                        fatura.forma_pagamento.chave &&
                        fatura.forma_pagamento.chave != ''
                      "
                      class=""
                    >
                      <b
                        >{{
                          fatura.forma_pagamento.tipo == "PIX"
                            ? "Chave: "
                            : "Conta " + fatura.forma_pagamento.tipo + ":"
                        }}
                      </b>
                      {{ fatura.forma_pagamento.chave }}
                      {{
                        fatura.forma_pagamento.tipo !== "PIX" &&
                        fatura.forma_pagamento.digito != ""
                          ? " - " + fatura.forma_pagamento.digito
                          : ""
                      }}
                    </span>
                    <span
                      v-if="
                        fatura.forma_pagamento.numero &&
                        fatura.forma_pagamento.numero != '' &&
                        fatura.forma_pagamento.tipo != 'PIX'
                      "
                      class="ml-2"
                    >
                      <b
                        >{{
                          fatura.forma_pagamento.tipo == "PIX"
                            ? "Número:"
                            : "Conta " + fatura.forma_pagamento.tipo + ":"
                        }}
                      </b>
                      {{ fatura.forma_pagamento.numero }}
                      {{
                        fatura.forma_pagamento.digito != ""
                          ? " - " + fatura.forma_pagamento.digito
                          : ""
                      }}
                    </span>
                    <span
                      v-if="
                        fatura.forma_pagamento.tipoChave &&
                        fatura.forma_pagamento.tipoChave != ''
                      "
                    >
                      <b>Tipo Chave: </b> {{ fatura.forma_pagamento.tipoChave }}
                    </span>
                  </small>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="fatura.desconto_itens & fatura.desconto_itens.length>0">
            <b>Descontos Obrigatórios - Devolução</b>
            <table
              class="table table-sm"
              style="font-size: 9px"
            >
              <thead>
                <tr>
                  <th>Devoção</th>
                  <th>Venda</th>
                  <th>Data</th>
                  <th>Paciente</th>
                  <th>Item</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in fatura.desconto_itens"
                  :key="`it_${item.id}`"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.venda_id }}</td>
                  <td>{{ item.venda_data | moment("DD/MM/YYYY") }}</td>
                  <td>{{ item.paciente }}</td>
                  <td>{{ item.descricao }}</td>
                  <td>{{ item.vl_custo | currency }}</td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
          <b>Exames / Consultas / Serviços realizados</b>

          <table
            class="table table-sm table-"
            style="font-size: 9px"
          >
            <thead>
              <tr>
                <th class="text-center">Código</th>
                <th class="text-center">Dt. Venda</th>
                <th class="text-center">Tipo</th>
                <th class="text-center">Exame / Consulta / Produto</th>
                <th>Consumidor</th>
                <th class="text-center">Valor</th>
              </tr>
            </thead>
            <tbody
              v-for="it in fatura.itens"
              :key="`tr_lni_item_${it.id}_${Math.random(232323232)}`"
            >
              <!-- <tr
                v-if="
                  fatura.itens.indexOf(it) == 34 ||
                    fatura.itens.indexOf(it) == 77 ||
                    fatura.itens.indexOf(it) == 118 ||
                    fatura.itens.indexOf(it) == 162 ||
                    fatura.itens.indexOf(it) == 205 ||
                    fatura.itens.indexOf(it) == 246 ||
                    fatura.itens.indexOf(it) == 290
                "
                class="html2pdf__page-"
              >
                <br /><br /><br />
                <br /><br /><br />
              </tr> -->
              <tr>
                <td class="text-center">{{ it.venda_item_id }}</td>
                <td class="text-center">
                  {{ it.dt_venda | moment("DD/MM/YYYY") }}
                </td>
                <td class="text-center">{{ it.tipo }}</td>
                <td class="text-left">
                  {{ it.descricao }} |
                  <small>{{ it.medicoNome }} ({{ it.especialidade }}</small>
                </td>
                <td>
                  <i class="fa fa-user" /> {{ it.clienteNome }} <br />
                  <small class="pl-3"
                    ><i class="fab fa-accessible-icon"></i>
                    {{ it.pacienteNome }}</small
                  >
                </td>
                <td class="text-center">{{ it.vl_total | currency }}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section class="pdf-item">
          <div style="page-break-after: always; page-break-inside: avoid"></div>
          <div
            v-for="n in 2"
            :key="`rec_n_${n}`"
            style="border-bottom: 2px dashed #333; padding-bottom: 25px"
          >
            <div class="card mt-4">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <h5 class="mb-0 pb-0">
                    {{ fatura.entidadeNome }}
                    <small>({{ fatura.itens[0].especialidade }})</small>
                  </h5>
                  <div class="mb-0 pb-0">
                    <small> <b>CPF:</b> {{ fatura.cpf }} </small>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-4">
                    FRANCA - SP
                    {{ fatura.crm }}
                  </div>
                  <div class="col-4 text-center">
                    <h2><b>RECIBO</b></h2>
                  </div>
                  <div class="col-4 text-right">
                    <h4>{{ fatura.vl_total | currencyWithMask }}</h4>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12">
                    <b>Código de Controle:</b> {{ fatura.id }}
                  </div>
                  <div class="col-12">
                    <b>Recebi(emos) de: </b> Social Serviços Ambulatoriais de
                    Saúde
                  </div>
                  <div class="col-12">
                    <b>O valor de: </b>
                    <span style="text-transform: capitalize">{{
                      fatura.valorExt
                    }}</span>
                  </div>
                  <div class="col-12">
                    <b>Referente á: </b> {{ fatura.referente }}
                  </div>

                  <div class="col-6">
                    <b>Firmo(amos) o presente:</b>
                    <br />
                    <br />
                    <small v-if="fatura.forma_pagamento">
                      <b>Forma de Pagamento:</b>
                      {{
                        ["Corrente", "Poupança"].includes(
                          fatura.forma_pagamento.tipo
                        )
                          ? "Bancário - " + fatura.forma_pagamento.tipo
                          : fatura.forma_pagamento.tipo
                      }}<br />
                      <span
                        v-if="
                          fatura.forma_pagamento.banco &&
                          fatura.forma_pagamento.banco != '' &&
                          fatura.forma_pagamento.tipo != 'PIX'
                        "
                      >
                        <b
                          >{{
                            fatura.forma_pagamento.tipo == "PIX"
                              ? "Tipo:"
                              : "Banco:"
                          }}
                        </b>
                        {{ fatura.forma_pagamento.banco }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.agencia &&
                          fatura.forma_pagamento.agencia != '' &&
                          fatura.forma_pagamento.tipo != 'PIX'
                        "
                      >
                        <br />
                        <b>Agencia: </b> {{ fatura.forma_pagamento.agencia }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.chave &&
                          fatura.forma_pagamento.chave != ''
                        "
                        class=""
                      >
                        <b
                          >{{
                            fatura.forma_pagamento.tipo == "PIX"
                              ? "Chave: "
                              : "Conta " + fatura.forma_pagamento.tipo + ":"
                          }}
                        </b>
                        {{ fatura.forma_pagamento.chave }}
                        {{
                          fatura.forma_pagamento.tipo !== "PIX" &&
                          fatura.forma_pagamento.digito != ""
                            ? " - " + fatura.forma_pagamento.digito
                            : ""
                        }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.numero &&
                          fatura.forma_pagamento.numero != '' &&
                          fatura.forma_pagamento.tipo != 'PIX'
                        "
                        class="ml-2"
                      >
                        <b
                          >{{
                            fatura.forma_pagamento.tipo == "PIX"
                              ? "Número:"
                              : "Conta " + fatura.forma_pagamento.tipo + ":"
                          }}
                        </b>
                        {{ fatura.forma_pagamento.numero }}
                        {{
                          fatura.forma_pagamento.digito != ""
                            ? " - " + fatura.forma_pagamento.digito
                            : ""
                        }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.tipoChave &&
                          fatura.forma_pagamento.tipoChave != ''
                        "
                      >
                        <b>Tipo Chave: </b>
                        {{ fatura.forma_pagamento.tipoChave }}
                      </span>
                    </small>
                  </div>
                  <div class="col-6 text-right">
                    <b>FRANCA - SP, {{ dataAtual }}</b>
                    <br /><br /><br /><br /><br /><br />
                    <div class="text-center">
                      <hr class="mb-0" />
                      {{ fatura.entidadeNome }}<br />
                      {{ fatura.cpf }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </print-component>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ContasPagarLib from "../../../../libs/ContasPagarLib";
import PrintComponent from "../../../Print/PrintComponent.vue";
import numero from "numero-por-extenso";
import PrinterWindowHelper from "../../../../helpers/PrinterWindowHelper";

export default {
  components: { PrintComponent },
  props: {
    faturaId: Number,
    downloadPDF: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      printNow: false,
      fatura: null,
      canPrint: false,
      dataAtual: moment().format("DD # MMMM # YYYY").split("#").join("de"),
    };
  },
  watch: {},
  computed: {},
  methods: {
    afterDownload(){
      if(this.downloadPDF){
        this.$emit("close")
      }
    },
    imprimir() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML, 15);
    },
    async carregar() {
      if (this.faturaId) {
        let res = await ContasPagarLib.getFaturas({ id: this.faturaId });
        if (res && res.success) {
          this.fatura = res.data[0];
          this.fatura.data = moment(this.fatura.dt_vencimento).format(
            "DD/MM/YYYY"
          );

          this.fatura.valorExt = numero.porExtenso(
            this.fatura.vl_total,
            numero.estilo.monetario
          );

          if (
            this.fatura.forma_pagamento &&
            this.fatura.forma_pagamento.banco
          ) {
            console.log(1);
            let bancoOpts = require("../../../ContasBancarias/bancoOptions");
            console.log(2, bancoOpts);

            let fnd = bancoOpts.bancoOptions.find(
              (x) => x.value == this.fatura.forma_pagamento.banco
            );
            console.log(3, fnd);

            if (fnd) {
              this.fatura.forma_pagamento.banco = fnd.text;
            }
            console.log(4, this.forma_pagamento);
          }

          this.printNow = true;
          console.log("item", this.fatura);

          await this.$nextTick();
          setTimeout(() => {
            //this.imprimir();
            this.canPrint = true;
          }, 1000);
        }
      }
    },
    setTipoContaIcon(tipo) {
      let icon;
      if (tipo == "Dinheiro") {
        icon = "dollar-sign";
      }
      if (tipo == "Poupança") {
        icon = "piggy-bank";
      }

      if (tipo == "Corrente") {
        icon = "university";
      }

      if (tipo == "PIX") {
        icon = "mobile";
      }

      return icon;
    },
  },
};
</script>

<style lang="scss" scoped></style>
