var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.historicoObj.pagamentoFornecedor &&
      _vm.historicoObj.pagamentoFornecedor.length > 0
    )?_c('div',[_c('b-table',{ref:"table",attrs:{"items":_vm.historicoObj.pagamentoFornecedor,"fields":[
        { key: 'id', label: 'Código', class: 'text-center' },
        { key: 'dt_vencimento', label: 'Vencimento', class: 'text-center' },
        { key: 'referente', label: 'Referente á', class: 'text-left' },
        { key: 'descricao', label: 'Descrição', class: 'text-left' },
      ],"striped":"","bordered":"","outlined":"","select-mode":"single","selectable":"","head-variant":"dark","responsive":"","hover":""},on:{"row-clicked":_vm.imprimirFatura,"row-selected":_vm.imprimirFatura},scopedSlots:_vm._u([{key:"cell(dt_vencimento)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.dt_vencimento,"DD/MM/YYYY"))+" ")]}}],null,false,1093029867)})],1):_c('div',[_c('div',{staticClass:"text-center"},[_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v("Nenhum pagamento de fornecedor encontrado")])],1)]),_c('b-modal',{attrs:{"id":"modal-imprimir","hide-footer":"","title":"Impressão Fatura","size":"lg"}},[_c('contas-pagar-fatura-impressao',{attrs:{"fatura-id":_vm.editID}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }