<template>
  <div>
    <div
      v-if="
        historicoObj.pagamentoFornecedor &&
        historicoObj.pagamentoFornecedor.length > 0
      "
    >
      <b-table
        :items="historicoObj.pagamentoFornecedor"
        :fields="[
          { key: 'id', label: 'Código', class: 'text-center' },
          { key: 'dt_vencimento', label: 'Vencimento', class: 'text-center' },
          { key: 'referente', label: 'Referente á', class: 'text-left' },
          { key: 'descricao', label: 'Descrição', class: 'text-left' },
        ]"
        striped
        bordered
        outlined
        select-mode="single"
        selectable
        head-variant="dark"
        responsive
        hover
        @row-clicked="imprimirFatura"
        @row-selected="imprimirFatura"
        ref="table"
      >
        <template #cell(dt_vencimento)="row">
          {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
        </template>
      </b-table>
    </div>
    <div v-else>
      <div class="text-center">
        <b-alert
          show
          variant="info"
          >Nenhum pagamento de fornecedor encontrado</b-alert
        >
      </div>
    </div>
    <b-modal
      id="modal-imprimir"
      hide-footer
      title="Impressão Fatura"
      size="lg"
    >
      <contas-pagar-fatura-impressao :fatura-id="editID" />
    </b-modal>
  </div>
</template>

<script>
import ContasPagarFaturaImpressao from "../../Financeiro/ContasPagar/Fatura/ContasPagarFaturaImpressao.vue";
export default {
  components: { ContasPagarFaturaImpressao },
  props: {
    historicoObj: Object,
  },
  mounted() {},
  data() {
    return {
      editID: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    imprimirFatura(id) {
      console.log(id);
      if (this.user.isAdmin && id && id.length > 0) {
        this.editID = id && id.length > 0 ? id[0].id : 0;
        if (this.editID) this.$bvModal.show("modal-imprimir");
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
