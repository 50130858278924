import axios from '../utils/axios.js';


const getVendas = async (data) => {
    try {
        return await (await (axios.post("/contas_pagar/faturas/getVendas", data))).data;
    } catch (err) {
        console.log("erro get getVendas", err);
        return null
    }

}

const storeFatura = async (data) => {
    try {
        return await (await (axios.post("/contas_pagar/faturas/store", data))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}
const getFaturas = async (data) => {
    try {
        return await (await (axios.post("/contas_pagar/faturas/get", data))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}
const excluirFatura = async (data) => {
    try {
        return await (await (axios.post("/contas_pagar/faturas/delete", data))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}

const integracaoERP = async (id) => {
    try {
        return await (await (axios.post("/contas_pagar/faturas/integrarERP", { id }))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}



const baixaFatura = async (id) => {
    try {
        return await (await (axios.post("/contas_pagar/faturas/baixaFatura", { id }))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}







export default {
    getVendas,
    storeFatura,
    getFaturas,
    excluirFatura,
    integracaoERP,
    baixaFatura
}